import React from 'react'

export default function NotFound() {
  return (
    <div className="App">
      <article>
        Page Not Found(404)
      </article>
    </div>
  )
}
